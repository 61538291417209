import { AppStoreService } from './../../app.service';
import { Component, OnInit } from '@angular/core';
import { Page } from 'src/app/models/page';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MsgDialogComponent } from 'src/app/modals/msg-dialog/msg-dialog.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
	selector: 'app-pages',
	templateUrl: './pages.component.html',
	styleUrls: ['./pages.component.scss']
})
export class AdminPagesComponent implements OnInit {

	public Editor = ClassicEditor;
	page: Page;

	constructor(
		private _appStore: AppStoreService,
		private _matDialog: MatDialog
	) { }

	ngOnInit() {
	}

	editPage(page) {
		this.page = this._appStore.allPages.value.find(p => p.page == page);
	}

	save() {
		this._appStore.updatePage(this.page)
			.then(res => this._matDialog.open(MsgDialogComponent, { data: { message: "Page updated successfully!" } }));
	}

	test(event) {
		console.log(event);
	}

}
