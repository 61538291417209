import { User } from './../../models/user';
import { MsgDialogComponent } from './../../modals/msg-dialog/msg-dialog.component';
import { AuthService } from './../../auth/auth.service';
import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent {

	@ViewChild('loginForm') loginForm: NgForm;

	email;
	password;

	constructor(private _authService: AuthService,
		private _router: Router,
		private _dialog: MatDialog
	) { }

	loginUser() {
		this._authService.login(this.loginForm.value);
	}

}
