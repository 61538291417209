import { environment } from 'src/environments/environment';
import { AppStoreService } from './../../app.service';
import { Component, OnInit, ElementRef } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { Blog } from 'src/app/models/blog';

@Component({
	selector: 'app-blog',
	templateUrl: './blog.component.html',
	styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

	curPage;
	env;
	maxPerPage = 5;
	maxPages = 0;
	allBlogs: Blog[] = [];
	tagBlogs: Blog[] = [];
	tag;
	pagedBlogPosts = new BehaviorSubject<Blog[]>([]);

	constructor(
		public appStore: AppStoreService,
		private _elmRef: ElementRef
	) { }

	ngOnInit() {
		this.env = environment;
		this._elmRef.nativeElement.ownerDocument.body.style.background = 'lightgrey';
		this.appStore.blogTag.subscribe(tag => this.tag = tag);

		if (this.appStore.allBlogs.value.length == 0)
			this.appStore.getBlogPosts();

		combineLatest([this.appStore.blogTag, this.appStore.curBlogPage, this.appStore.allBlogs]).subscribe(data => {
			let tag = data[0];
			let page = data[1];
			let v = data[2];

			this.curPage = page;
			this.maxPages = Math.ceil(v.length / this.maxPerPage);
			this.allBlogs = v;

			if (tag != this.tag) this.filterBlogsByTag(tag);
			else this.tagBlogs = this.allBlogs.filter(blog => blog.tags && blog.tags.includes(tag));

			if (tag && page && v) this.gotoPage();
			else if (!tag && page && v) this.gotoPage();
		});
	}

	ngOnDestroy() {
		this._elmRef.nativeElement.ownerDocument.body.style.background = 'white';
	}

	filterBlogsByTag(tag, gotoPage1 = false) {
		this.appStore.blogTag.next(tag);
		this.tagBlogs = this.allBlogs.filter(blog => blog.tags && blog.tags.includes(tag));
		if (gotoPage1) this.appStore.curBlogPage.next(1);
	}

	gotoPage() {
		let curPage = this.appStore.curBlogPage.value;
		let blogs = (this.tagBlogs.length > 0) ? this.tagBlogs : this.allBlogs;
		this.maxPages = Math.ceil(blogs.length / this.maxPerPage);
		let min = (curPage * this.maxPerPage) - this.maxPerPage;
		let max = (curPage * this.maxPerPage) - 1;
		this.pagedBlogPosts.next(blogs.filter((blog, index) => index >= min && index <= max));
	}

	changePage() {
		this.appStore.curBlogPage.next(this.curPage);
	}

	clearFilter() {
		this.tag = null;
		this.tagBlogs = [];
		this.curPage = 1;
		this.appStore.curBlogPage.next(1);
		this.appStore.blogTag.next(null);
		this.gotoPage();
	}

}
