import { DownloadsComponent } from './pages/downloads/downloads.component';
import { AdminDownloadsComponent } from './admin/downloads/downloads.component';
import { AppStoreService } from './app.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { VideosComponent } from './pages/videos/videos.component';
import { LiveComponent } from './pages/live/live.component';
import { BlogComponent } from './pages/blog/blog.component';
import { ContactComponent } from './pages/contact/contact.component';
import { LoginComponent } from './pages/login/login.component';
import { PanelComponent } from './admin/panel/panel.component';
import { FooterComponent } from './footer/footer.component';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { MsgDialogComponent } from './modals/msg-dialog/msg-dialog.component';
import { AdminBlogComponent } from './admin/blog/blog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminPagesComponent } from './admin/pages/pages.component';
import { PageContentPipe } from './pipes/page-content.pipe';
import { TagPipe } from './pipes/tag.pipe';
import { CKEditorModule } from 'ckeditor4-angular';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { BlogViewComponent } from './pages/blog-view/blog-view.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { environment } from 'src/environments/environment';
import { ConfirmDialogComponent } from './modals/confirm-dialog/confirm-dialog.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NewhostComponent } from './pages/newhost/newhost.component';

@NgModule({
  declarations: [
	AppComponent,
	ConfirmDialogComponent,
    DownloadsComponent,
    HeaderComponent,
    HomeComponent,
    VideosComponent,
    LoginComponent,
    LiveComponent,
    BlogComponent,
    AdminBlogComponent,
    ContactComponent,
    LoginComponent,
    PanelComponent,
    FooterComponent,
    AdminDownloadsComponent,
    DashboardComponent,
    MsgDialogComponent,
    AdminPagesComponent,
    TagPipe,
    PageContentPipe,
    SafeHtmlPipe,
    BlogViewComponent,
    NewhostComponent
  ],
  imports: [
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
	AngularFireStorageModule, // storage
	AngularFireAnalyticsModule, // analytics
    BrowserAnimationsModule,
    BrowserModule,
    CKEditorModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
	MatIconModule,
	MatProgressBarModule,
    MatSelectModule,
    ReactiveFormsModule
  ],
  providers: [
    AppStoreService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    MsgDialogComponent
  ]
})
export class AppModule { }
