import { Component, OnInit } from '@angular/core';
import { AppStoreService } from 'src/app/app.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {

  videosPerPage = 12;
  ytVideos;
  ytReady = false;

  constructor(
    private _appStore: AppStoreService,
    private _san: DomSanitizer
  ) { }

  ngOnInit() {
    this.startUp();
  }

  async startUp() {
    this.ytVideos = await this._appStore.getYTVideos(this.videosPerPage);
    if (this.ytVideos && this.ytVideos.items.length > 0) this.ytReady = true;
  }

  async gotoPage(pageToken){
    this.ytVideos = await this._appStore.getYTVideos(this.videosPerPage, pageToken);
    if (this.ytVideos && this.ytVideos.items.length > 0) this.ytReady = true;
  }

  loadVideo(videoId){
    window.open(`https://www.youtube.com/embed/${videoId}?autoplay=1`);
  }

}
