import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})

export class AuthGuard implements CanActivate {

	constructor(
		private _afAuth: AngularFireAuth,
		private _router: Router,
	) { }

	canActivate(): Observable<boolean> | Promise<boolean> | boolean {
		return this._afAuth.authState.pipe(
			map(user => {
				if (!user) this._router.navigate(['/login']);
				return user ? true : false;
			})
		);
	}
}
