import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/user';

@Injectable({
	providedIn: 'root'
})

export class AuthService {

	authUser$ = new BehaviorSubject<any>(null);
	eventAuthError = new BehaviorSubject<string>("");
	eventAuthError$ = this.eventAuthError.asObservable();
	headers = new HttpHeaders({ 'Content-Type': 'application/json' });
	isAdmin: Observable<boolean>;
	user$ = new BehaviorSubject<User>(null);

	constructor(
		private _afAuth: AngularFireAuth,
		private _db: AngularFirestore,
		private _router: Router,
	) {
		this._afAuth.authState.subscribe(usr => {
			if (!usr) return;
			this.authUser$.next(usr);
		});
	}

	public get currentUserValue(): User {
		return this.user$.value;
	}

	login(data) {
		let email = data.email;
		let password = data.password;
		this._afAuth.signInWithEmailAndPassword(email, password)
			.then(userCredential => {
				if (userCredential)
					this._router.navigate(['/admin']);
				return userCredential;
			})
			.catch(error => {
				this.eventAuthError.next(error);
				return error;
			});
	}

	logout(redirect = null) {
		this.authUser$.next(null);
		this.user$.next(null);
		this.isAdmin = new Observable(obs => obs.next(false));
		this._afAuth.signOut();
		if (redirect) this._router.navigateByUrl(redirect);
	}
}
