import { AppStoreService } from './../../app.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/storage';

@Component({
	selector: 'app-downloads',
	templateUrl: './downloads.component.html',
	styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent implements OnInit {

	fragment;

	constructor(
		private _appStore: AppStoreService,
		private _storage: AngularFireStorage,
		private _router: ActivatedRoute
	) { }

	ngOnInit() {
		if (this._appStore.allUploads.value.length == 0)
			this._appStore.getUploads();

			// this._appStore.uploads.subscribe(uploads => {
			// 	uploads.forEach(upload => {
			// 		if (!upload.downloadURL) {
			// 			let docPath = this._storage.ref('').child(upload.file);
			// 			console.log(docPath);
			// 			docPath.getDownloadURL().subscribe(d => console.log(d), e => console.error(e));
			// 			// let ref = this._storage.ref(upload.file);
			// 			// console.log('here', ref);
			// 			// ref.getDownloadURL().subscribe(d => console.log("res", d), e => console.log("err", e));
			// 			// console.log(upload);
			// 			// this._appStore.updateFile(upload);
			// 		}
			// 	})
			// });

		this._router.fragment.subscribe(fragment => { this.fragment = fragment; });
	}

	ngAfterViewChecked() {
		try { if (this.fragment) document.querySelector('#' + this.fragment).scrollIntoView(); }
		catch (e) { }
	}

	filteredDownloads(category) {
		return (!category) ?
			this._appStore.allUploads.value :
			this._appStore.allUploads.value.filter(upload => upload.category == category);
	}

}
