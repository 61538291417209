import { AppStoreService } from './../../app.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.scss']
})
export class LiveComponent implements OnInit {

  liveVideoUrl;
  ytReady = false;

  constructor(
    private appStore: AppStoreService,
    private _san: DomSanitizer,
    private _router: Router,
  ) { }

  ngOnInit() {
    this.startUp();
  }
  

  async startUp() {
    if (!this.appStore.ytLive.value) {
      let temp = await this.appStore.getYTLiveStatus();
      if (temp) {
        this.liveVideoUrl = 
          this._san.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${this.appStore.ytLive.value.id.videoId}`);
        this.ytReady = true;
      }
    } else {
      this.liveVideoUrl = this._san.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${this.appStore.ytLive.value.id.videoId}`);
      this.ytReady = true;
    }
  }

}
