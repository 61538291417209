import { AuthService } from './../../auth/auth.service';
import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
	selector: 'app-panel',
	templateUrl: './panel.component.html',
	styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit {

	pageTitle = "Admin";
	mobileMenu = false;

	constructor(
		public authService: AuthService,
		private _elmRef: ElementRef,
		private _router: Router,
	) { }

	ngOnInit() {
		let routerSplit = this._router.url.split('/');
		this.pageTitle = routerSplit[2].toUpperCase();

		if (routerSplit[1] === 'admin') this._elmRef.nativeElement.ownerDocument.body.style.background = 'lightgrey';
		else this._elmRef.nativeElement.ownerDocument.body.style.background = 'white';

		this._router.events.subscribe(e => {
			if (e instanceof NavigationEnd) {
				let eSplit = e.url.split('/');
				this.pageTitle = eSplit[2].toUpperCase();
				if (eSplit[1] === 'admin') this._elmRef.nativeElement.ownerDocument.body.style.background = 'lightgrey';
				else this._elmRef.nativeElement.ownerDocument.body.style.background = 'white';
			}
		});
	}

	toggleMobileMenu() {
		this.mobileMenu = !this.mobileMenu;
		if (this.mobileMenu == true) this._elmRef.nativeElement.ownerDocument.body.classList.add('modal-open');
		else this._elmRef.nativeElement.ownerDocument.body.classList.remove('modal-open');
	}

	logout() {
		setTimeout(() => {
			this._elmRef.nativeElement.ownerDocument.body.style.background = 'white';
			this._router.navigateByUrl('/home');
		}, 500);
		this.authService.logout();
	}

}
