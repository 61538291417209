export const environment = {
	production: false,
	apiUrl: 'https://www.andremourinho.com/api',
	portal: 'metipso',
	firebaseConfig: {
		apiKey: "AIzaSyCnNZb_zPPMqm_V4onLUH16ajVBsBnH_K0",
		authDomain: "metipso.firebaseapp.com",
		databaseURL: "https://metipso.firebaseio.com",
		projectId: "metipso",
		storageBucket: "metipso.appspot.com",
		messagingSenderId: "444649280447",
		appId: "1:444649280447:web:31d8af50f464bdde0b870a",
		measurementId: "G-GCCLYMBEEJ"
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
