import { MatDialog } from '@angular/material/dialog';
import { AppStoreService } from './../../app.service';
import { Component, ViewChild, OnInit } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MsgDialogComponent } from 'src/app/modals/msg-dialog/msg-dialog.component';

@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

	contactForm: FormGroup;

	constructor(
		private _appStore: AppStoreService,
		private _dialog: MatDialog,
		private _formBuilder: FormBuilder
	) { }

	ngOnInit() {
		this.contactForm = this._formBuilder.group({
			name: ['', Validators.required],
			email: ['', Validators.required],
			message: ['', Validators.required],
			phone: ['']
		});

	}

	submit() {
		this._appStore.email(this.contactForm.value).subscribe(res => {
			this._dialog.open(MsgDialogComponent, { width: '400px', data: { title: 'Success', message: "Message has been submitted successfully!" } });
			this.contactForm.reset();
		}, err => {
			this._dialog.open(MsgDialogComponent, { width: '400px', data: { title: 'Oops..', message: err.error.message } });
		});
	}
}
