import { HttpClient } from '@angular/common/http';
import { AppStoreService } from './../../app.service';
import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';

declare var Instafeed: any;

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

	private fb_token;

	curYtVideoURL;
	fbContainerURL;
	fbWidth;
	resizeTimeout;
	runOnce = false;
	ytVideos;
	ytReady = false;

	@ViewChild('fbContainer') fbContainer: ElementRef;

	constructor(
		public appStore: AppStoreService,
		private _afs: AngularFirestore,
		private _san: DomSanitizer,
		private _http: HttpClient,
		private _activeRoute: ActivatedRoute
	) { }

	ngOnInit() {
		this.startUp();
		if (this.fbContainer) this.getFBUrl(this.fbContainer.nativeElement.clientWidth - 30);
		this.appStore.getYTLiveStatus();
	}

	ngAfterViewInit() {
        this.getFBUrl(this.fbContainer.nativeElement.clientWidth - 30);
		// @ts-ignore
		twttr.widgets.load();
	}

	@HostListener('window:resize')
	onWindowResize() {
		if (this.resizeTimeout)
			clearTimeout(this.resizeTimeout);

		this.resizeTimeout = setTimeout((() => {
			this.getFBUrl(this.fbContainer.nativeElement.clientWidth - 30);
		}).bind(this), 500);
	}

	getFBUrl(width: number) {
		this.fbWidth = width && width < 500 ? width : 500;
		this.fbContainerURL =
			this._san.bypassSecurityTrustResourceUrl(
				'https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FtheMetipsoPortal&tabs=timeline&width='
				+ width +
				'&height=350&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId');
	}

	loadVideo(videoId: string) {
		this.curYtVideoURL = this._san.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${videoId}?autoplay=1`);
	}

	async startUp() {

		if (!this.appStore.ytLive.value) {
			let temp = await this.appStore.getYTLiveStatus();
			if (temp) {
				this.curYtVideoURL =
					this._san.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${this.appStore.ytLive.value.id.videoId}`);
			}
			this.ytVideos = await this.appStore.getYTVideos(3);
			if (this.ytVideos && this.ytVideos.items.length > 0 && !temp)
				this.curYtVideoURL =
					this._san.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${this.ytVideos.items[0].snippet.resourceId.videoId}`);
			this.ytReady = true;
		} else {
			this.curYtVideoURL = this._san.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${this.appStore.ytLive.value.id.videoId}`);
			this.ytVideos = await this.appStore.getYTVideos(3);
			this.ytReady = true;
		}
	}

	gotoSplashPage() {
		window.open('https://metipsoportal.splashthat.com/', '_blank');
	}

	async getAccessToken() {
		this.fb_token = await this._http.get("https://graph.facebook.com/oauth/access_token?client_id=474419366638473&client_secret=09538c2c7bf33dd6cac26bba21e348d1&grant_type=client_credentials").toPromise();
		console.log(this.fb_token);
	}

}
