import { AuthService } from './auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';


declare let gtag: Function

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'metipso';
	adminRoute = false;

	constructor(
		public authService: AuthService,
		public router: Router
	) { }

	ngOnInit() {
		this.router.events.subscribe(e => {
			if (e instanceof NavigationEnd) {
				this.adminRoute = e.urlAfterRedirects.includes('admin');
				
                gtag('set', 'page', `https://www.metipsoportal.com${e.urlAfterRedirects}`);
                gtag('send', 'pageview');
			}
		});
	}
}
