import { AppStoreService } from './../app.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'tagPipe',
    pure: false
})
export class TagPipe implements PipeTransform {
    transform(value: any) {
        let tags = value.split(',');
        return tags;
    }
}