import { Blog } from './../../models/blog';
import { AppStoreService } from './../../app.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MatDialog } from '@angular/material/dialog';
import { MsgDialogComponent } from 'src/app/modals/msg-dialog/msg-dialog.component';
import { ConfirmDialogComponent } from 'src/app/modals/confirm-dialog/confirm-dialog.component';

@Component({
	selector: 'app-blog',
	templateUrl: './blog.component.html',
	styleUrls: ['./blog.component.scss']
})
export class AdminBlogComponent implements OnInit {

	public Editor = ClassicEditor;
	blogForm: FormGroup;
	content;
	editingBlog: Blog;
	filteredBlogPosts = new BehaviorSubject<Blog[]>([]);
	filterTag;

	constructor(
		private _formBuilder: FormBuilder,
		private appStore: AppStoreService,
		private _matDialog: MatDialog
	) { }

	ngOnInit() {
		if (this.appStore.allBlogs.value.length == 0)
			this.appStore.getBlogPosts();

		this.appStore.allBlogs.subscribe(v => this.filterBlogs());

		this.blogForm = this._formBuilder.group({
			title: ['', Validators.required],
			content: ['', Validators.required],
			created_at: [''],
			updated_at: [''],
			tags: ['']
		});
	}

	editBlog(blog: Blog) {
		this.blogForm.get('title').setValue(blog.title);
		this.blogForm.get('content').setValue(blog.content);
		this.blogForm.get('tags').setValue(blog.tags);
        this.blogForm.get('updated_at').setValue(new Date());
		this.editingBlog = blog;
	}

	filterBlogs() {
		this.filteredBlogPosts.next((!this.filterTag) ?
			this.appStore.allBlogs.value :
			this.appStore.allBlogs.value.filter(blog => blog.tags.split(',').indexOf(this.filterTag.toLowerCase()) > -1));
	}

	save() {
        this.blogForm.get('created_at').setValue(new Date());
		this.blogForm.get('updated_at').setValue(new Date());
		
		console.log(this.blogForm.value);

		this.appStore.postBlog(this.blogForm.value).then(r => {
			this.appStore.getBlogPosts();
			this.blogForm.reset();
		});
	}

	update() {
		if (this.blogForm.valid) 
			this.appStore.updateBlog(this.blogForm.value).then(i => {
				this.cancel();
				this._matDialog.open(MsgDialogComponent, { data: { message: "Blog updated successfully!" } });
			});
	}

	deleteBlog(blog: Blog) {
		this._matDialog.open(ConfirmDialogComponent, {
            width: '350px',
            data: { message: `Are you sure you want to delete <br /><strong>${blog.title}</strong>?` }
        }).afterClosed().subscribe(r => { 
			if (r) this.appStore.deleteBlog(blog);
		});
	}

	cancel() {
		this.blogForm.reset();
		this.editingBlog = null;
	}

}
