import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newhost',
  templateUrl: './newhost.component.html',
  styleUrls: ['./newhost.component.scss']
})
export class NewhostComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
