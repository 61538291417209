import { AuthService } from './../../auth/auth.service';
import { Uploads } from './../../models/uploads';
import { AppStoreService } from './../../app.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { MsgDialogComponent } from 'src/app/modals/msg-dialog/msg-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/modals/confirm-dialog/confirm-dialog.component';

@Component({
	selector: 'app-downloads',
	templateUrl: './downloads.component.html',
	styleUrls: ['./downloads.component.scss']
})
export class AdminDownloadsComponent implements OnInit {

	@ViewChild('uploadInput') uploadInput: ElementRef;
	category;
	error: string;
	filterCategory = '';
	filteredUploads = new BehaviorSubject<Uploads[]>([]);
	form: FormGroup;
    file: File;
    ref: AngularFireStorageReference;
    task: AngularFireUploadTask;
    uploadProgress: Observable<number>;
	filePath;

	constructor(
		private _formBuilder: FormBuilder,
		public appStore: AppStoreService,
		private _storage: AngularFireStorage,
		private _matDialog: MatDialog
	) { }

	ngOnInit() {
		this.form = this._formBuilder.group({
			download: [''],
            file: [''],
            category: [''],
            uploadDate: [''],
		});

		if (this.appStore.allUploads.value.length == 0)
			this.appStore.getUploads();

		this.appStore.uploads.subscribe(uploads => {
			uploads.forEach(async upload => {			
				if (!upload.downloadURL) {
					this.ref = this._storage.ref(upload.file);
					upload.downloadURL = await this.ref.getDownloadURL().toPromise();
					console.log(upload);
					this.appStore.updateFile(upload);
				}
			})
			this.filterFiles();
		});
	}

	filterFiles() {
		this.filteredUploads.next((!this.filterCategory) ?
			this.appStore.allUploads.value :
			this.appStore.allUploads.value.filter(upload => upload.category == this.filterCategory));
	}

	onFileChange(event) {
		if (event.target.files.length > 0) {
			this.file = event.target.files[0];
			const id = this.file.name;
			this.ref = this._storage.ref(id);
			this.form.patchValue({ file: this.file.name });
		}
	}

    cancel() {
		this.uploadInput.nativeElement.value = '';
		this.category = null;
	}

	deleteFile(upload: Uploads) {
		this._matDialog.open(ConfirmDialogComponent, {
            width: '350px',
            data: { message: `Are you sure you want to delete <br /><strong>${upload.file}</strong>?` }
        }).afterClosed().subscribe(r => { 
			this._storage.ref(upload.file).delete();
			if (r) this.appStore.deleteFile(upload);
		});
	}
	
	onSubmit() {
		if (!this.category) this.category = "Other";

		this.task = this.ref.put(this.file);
		this.uploadProgress = this.task.percentageChanges();
		this.task.then(r => {
			if (r.state == "success") {
				this.form.get('category').setValue(this.category);
				this.form.get('file').setValue(this.file.name);
				this.form.get('uploadDate').setValue(new Date());
				this.appStore.upload(this.form.value).then(i => {
					this._matDialog.open(MsgDialogComponent, { data: { message: "Document uploaded and saved successfully!" } });
					this.cancel();
				});
			} else {
				this._matDialog.open(MsgDialogComponent, { data: { message: "There was an error uploading the document." } });
			}
		});
	}

}
