import { AppStoreService } from './../app.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pageContent',
    pure: false
})
export class PageContentPipe implements PipeTransform {
    constructor(private _appStore: AppStoreService) { }
    transform(value: any) {
        return this._appStore.getPage(value);
    }
}