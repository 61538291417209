import { PanelComponent } from './admin/panel/panel.component';
import { ContactComponent } from './pages/contact/contact.component';
import { BlogComponent } from './pages/blog/blog.component';
import { LiveComponent } from './pages/live/live.component';
import { VideosComponent } from './pages/videos/videos.component';
import { HomeComponent } from './pages/home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from './auth/auth-guard.service';
import { AdminBlogComponent } from './admin/blog/blog.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { AdminPagesComponent } from './admin/pages/pages.component';
import { AdminDownloadsComponent } from './admin/downloads/downloads.component';
import { DownloadsComponent } from './pages/downloads/downloads.component';
import { BlogViewComponent } from './pages/blog-view/blog-view.component';
import { NewhostComponent } from './pages/newhost/newhost.component';

const routes: Routes = [
  {
      path: '',
      redirectTo: 'home',
      pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'home/:step',
    component: HomeComponent
  },
  {
    path: 'tv',
    component: VideosComponent
  },
  {
    path: 'downloads',
    component: DownloadsComponent
  },
  {
    path: 'live',
    component: LiveComponent
  },
  {
    path: 'blog',
    component: BlogComponent
  },
  {
    path: 'newhost',
    component: NewhostComponent
  },
  {
    path: 'blog/:id/:title',
    component: BlogViewComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
	// Login Route
	path: 'login',
	component: LoginComponent
  },
  {
    // ADMIN ROUTE - PROTECTED **
    path: 'admin',
    component: PanelComponent,
    children: [
      {
        // ADMIN ROUTE - DASHBOARD - PROTECTED **
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        // ADMIN ROUTE - DASHBOARD - PROTECTED **
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },
      {
        // ADMIN ROUTE - DASHBOARD - PROTECTED **
        path: 'pages',
        component: AdminPagesComponent,
        canActivate: [AuthGuard],
      },
      {
        // ADMIN ROUTE - DASHBOARD - PROTECTED **
        path: 'downloads',
        component: AdminDownloadsComponent,
        canActivate: [AuthGuard],
      },
      {
        // ADMIN ROUTE - DASHBOARD - PROTECTED **
        path: 'blog',
        component: AdminBlogComponent,
        canActivate: [AuthGuard],
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
