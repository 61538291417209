import { AuthService } from './auth/auth.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Uploads } from './models/uploads';
import { Page } from './models/page';
import { Blog } from './models/blog';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './modals/confirm-dialog/confirm-dialog.component';
import { MsgDialogComponent } from './modals/msg-dialog/msg-dialog.component';

@Injectable({
	providedIn: 'root'
})
export class AppStoreService {

	curYtVideoURL;
	ytLive: BehaviorSubject<any> = new BehaviorSubject(null);
	// uploads: BehaviorSubject<Uploads[]> = new BehaviorSubject([]);

	uploadsCollection: AngularFirestoreCollection<Uploads>;
	allUploads = new BehaviorSubject<Uploads[]>([]);
	uploads: Observable<Uploads[]>;

	pagesCollection: AngularFirestoreCollection<Page>;
	allPages = new BehaviorSubject<Page[]>([]);
	pages: Observable<Page[]>;

	blogsCollection: AngularFirestoreCollection<Blog>;
	allBlogs = new BehaviorSubject<Blog[]>([]);
	blogs: Observable<Blog[]>;
	curBlogPage: BehaviorSubject<number> = new BehaviorSubject(1);
	blogTag: BehaviorSubject<string> = new BehaviorSubject(null);

	constructor(
		private _afs: AngularFirestore,
		private _http: HttpClient,
		private _authService: AuthService,
		private _matDialog: MatDialog
	) {
		this.getPages();
	}



	/*************************************************************************
	 *   MANAGE BLOGS
	 ************/

	getBlogPosts() {
		this.blogsCollection = this._afs.collection('blog', ref => {
            return ref.orderBy('created_at');
        });		
		this.blogs = this.blogsCollection.valueChanges({ idField: 'id' });
		this.blogs.subscribe(blogs => {
			blogs.forEach(s => {
				if (typeof s.created_at != 'string') s.created_at = new Date(s.created_at['seconds'] * 1000);
				if (typeof s.updated_at != 'string') s.updated_at = new Date(s.updated_at['seconds'] * 1000);
			});
			this.allBlogs.next(blogs)
		});
	}

	updateBlog(blog: Blog) {
		return this._afs.collection('blog').doc(blog.id).update(blog);
		// return this._http.put(`${environment.apiUrl}/v1/admin/blog/metipso`, blog);
	}

	postBlog(blog: Blog) {
		return this._afs.collection('blog').add(blog);
		// return this._http.post(`${environment.apiUrl}/v1/admin/blog/metipso`, blog);
	}

	deleteBlog(blog: Blog) {
		return this._afs.collection('blog').doc(blog.id).delete();
		// this._http.delete(`${environment.apiUrl}/v1/admin/blog/${blog._id}`).toPromise().then(res => this.getBlogPosts());
	}



	/*************************************************************************
	 *   MANAGE PAGES
	 ************/

	updatePage(page: Page) {
		return this._afs.collection('pages').doc(page.id).update(page);
	}

	getPages() {
		this.pagesCollection = this._afs.collection('pages');		
		this.pages = this.pagesCollection.valueChanges({ idField: 'id' });
		this.pages.subscribe(pages => this.allPages.next(pages));
	}

	getPage(pageName) {
		return this.allPages.value.find(p => p.page == pageName) ? this.allPages.value.find(p => p.page == pageName).content : null;
	}




	/*************************************************************************
	 *   MANAGE UPLOADS
	 ************/

	getUploads() {
		this.uploadsCollection = this._afs.collection('uploads');
		this.uploads = this.uploadsCollection.valueChanges({ idField: 'id' });
		this.uploads.subscribe(uploads => this.allUploads.next(uploads));
	}

	deleteFile(upload: Uploads) {
		return this._afs.collection('uploads').doc(upload.id).delete();
	}

	upload(upload: Uploads) {
		return this._afs.collection('uploads').add(upload);
	}

	updateFile(upload: Uploads) {
		return this._afs.collection('uploads').doc(upload.id).update(upload);
	}



	/*************************************************************************
	 *   YOUTUBE API CALLS
	 ************/

	getYTVideos(resultsPerPage, pageToken = null) {
		pageToken = pageToken ? "&pageToken=" + pageToken : '';
		return this._http.get(`https://www.googleapis.com/youtube/v3/playlistItems?playlistId=UUYX0URFAzQUMBykd9yZ6Xbw&part=snippet&key=AIzaSyCnNZb_zPPMqm_V4onLUH16ajVBsBnH_K0&maxResults=${resultsPerPage}${pageToken}`).toPromise();
	}

	getYTLiveStatus() {
		return this._http.get('https://www.googleapis.com/youtube/v3/search?eventType=live&part=snippet&channelId=UCYX0URFAzQUMBykd9yZ6Xbw&type=video&key=AIzaSyCnNZb_zPPMqm_V4onLUH16ajVBsBnH_K0&type=video')
			.toPromise().then((r: any) => {
				let res = r.items.length > 0 ? r.items[0] : null;
				this.ytLive.next(res);
				return res;
			});
	}



	/*************************************************************************
	 *   HELPER FUNCTIONS
	 ************/

	email(body) {
		let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		return this._http
			.post('https://us-central1-metipso.cloudfunctions.net/contactUs', body, { headers: headers });
	}

	debounce(func, wait, immediate = null) {
		let timeout;
		return () => {
			let context = this, args = arguments;
			let later = () => {
				timeout = null;
				if (!immediate) func.apply(context, args);
			}

			let callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		}
	}
}
