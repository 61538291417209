import { Component, OnInit } from '@angular/core';
import { Blog } from 'src/app/models/blog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppStoreService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-blog-view',
	templateUrl: './blog-view.component.html',
	styleUrls: ['./blog-view.component.scss']
})
export class BlogViewComponent implements OnInit {

	blog: Blog;
	env;

	constructor(
		private _appStore: AppStoreService,
		private _activeRoute: ActivatedRoute,
		private _router: Router
	) { }

	ngOnInit() {
		this.env = environment;
		this._activeRoute.params.subscribe(params => {
			if (!params.id || !params.title) return;

			if (this._appStore.allBlogs.value.length == 0)
				this._appStore.getBlogPosts();

			this._appStore.allBlogs.subscribe(b => {
				this.blog = b.find(c => c.id == params.id);
			});
		});
	}

	setTag(tag) {
		this._appStore.blogTag.next(tag);
		this._router.navigateByUrl('/blog');
	}

}
